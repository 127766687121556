.CommingSoon {
  margin: 10% auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120%;
}

.centerBox {
  height: 300px;
  width: 300px;
  border-radius: 9px;
  background-color: #d9d9d9;
}

.CommingSoon h3 {
  font-size: 32px;
  font-weight: 400;
}

@media (max-width: 1000px) {
  .CommingSoon {
    margin-top: 20%;
  }

  .centerBox {
    height: 250px;
    width: 250px;
  }

  .CommingSoon h3 {
    font-size: 28px;
  }

  .spaceMaker {
    height: 140px;
    width: 100%;
  }
}
