.App {
  text-align: center;
}

body {
  background-color: white;
  font-family: 'DM Sans', sans-serif;
}

textarea {
  font-family: 'DM Sans', sans-serif;
}
