.UserView {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 24px; */
  gap: 12px;
}

.ProfileImage {
  position: relative;
  border-radius: 8px;
  padding: 20px 12px;
  display: flex;
  flex-direction: column;
  width: 304px;
  height: 328px;
  flex-shrink: 0;
  justify-content: end;
  background-size: auto !important;
  /* align-items: end; */
  /* justify-content: left; */
  /* margin: 24px; */
  margin: 24px 0 0 0;
  /* gap: 12px; */
  box-shadow: 0px 4.192px 18.864px 0px rgba(155, 81, 224, 0.12);
}

.blur-background {
  filter: blur(1px);
}

.ConnectSave {
  display: flex;
  align-items: center;
}

.UserName {
  font-size: 40px;
  font-weight: bolder;
  line-height: 40px;
}

.text--0- {
  width: 100%;
  /* height: 50px; */
  color: #ffffffcc;
  /* font-size: 25px; */
  font-family: DM Sans, 'Medium';
  /* font-weight: 500; */
  text-align: left;
  vertical-align: center;
}

.container--0- {
  position: relative;
  width: 100%;
  height: 45px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.container--1- {
  position: relative;
  width: 328px;
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.UserDesignation {
  font-size: 14px;
  margin: 4px 0 10px 0;
}

.UserViewButton {
  display: flex;
  color: white;
  font-size: 14px;
  width: 50%;
  /* border-radius: 10px; */
  height: 45px;
  border: none;
  border-radius: 24px;
  box-shadow: 0px 4.192px 18.864px 0px rgba(155, 81, 224, 0.12);
  padding: 10.48px 25.152px 10.48px 16.768px;
  justify-content: center;
  align-items: center;
  gap: 8.384px;
  flex: 1 0;
  align-self: stretch;
}

.callemail {
  display: flex;
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  width: 50%;
  border-radius: 8px;
  height: 50px;
  border: 2px solid rgba(175, 82, 222, 0.16);
  background: #fff;
  box-shadow: 0px 4.192px 18.864px 0px rgba(155, 81, 224, 0.12);
  padding: 16px;
  justify-content: left;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
}

.callemail:hover {
  cursor: pointer;
}

.callemail img {
  height: 20px;
  width: 20px;
  filter: brightness(100);
}

.save {
  background: transparent;
  border: 1px solid #fff;
}

.connect {
  background-color: #9b51e0;
}

.UserViewButton img {
  height: 20px;
  width: 20px;
  filter: brightness(100) invert(1);
}

.UserViewButton:hover {
  background-color: #8635eec8;
  transition: 0.2s;
  cursor: pointer;
}

.about-me {
  width: 294px;
  padding: 21px 17px;
  border: 2px solid rgba(175, 82, 222, 0.16);
  border-radius: 8.38px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-shadow: 0px 4.192px 18.864px 0px rgba(155, 81, 224, 0.12);
}

.about-me-heading {
  font-size: 14.67px;
  font-weight: bold;
}

.data-bio {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.handles {
  width: 328px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}

.handle {
  width: 59px;
  height: 62px;
  padding: 14px 16px;
  border: 2px solid rgba(175, 82, 222, 0.16);
  box-shadow: 0px 4.192px 18.864px 0px rgba(155, 81, 224, 0.12);
  border-radius: 8.38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  transition: 0.3s ease;
}

.handle:hover {
  transform: scale(1.1);
  overflow: hidden;
}

.SocialLink h4 {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  color: black;
}

.BondUButton {
  width: 250px;
  border-radius: 4px;
  background: #9b51e0;
  border-width: 0px;
  height: 50%;
  color: #fff;
  font-family: 'DM Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.105px;
  box-shadow: 0px 3px 16.2px 0px rgba(94, 27, 156, 0.2);
}

.SendContactPopup {
  border-radius: 28px;
  z-index: 10;
  height: 450px;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.168);
  width: 300px;
  padding: 5vh;
  font-size: 18px;
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}

.PopUpHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SendContactForm {
  margin: 10px;
  text-align: center;
}

.SendContactPopup h1 {
  font-size: 28px;
  font-weight: 600;
  margin: 0 10px;
}

.SendContactForm input {
  margin: 0;
  width: 250px;
}

.TextField {
  margin: 20px 0;
  text-align: left;
}

.SendContactButton {
  color: #8635ee;
  background-color: white;
  height: 45px;
  width: 100px;
  font-size: 16px;
  font-weight: 600;
  border: 2px solid #8635ee;
  border-radius: 8px;
}

@media (max-width: 520px) {
  .SendContactPopup {
    width: 70vw;
  }

  .TextField input {
    width: 60vw;
  }

  .Hamburger img {
    height: 30px;
    width: 30px;
  }
}
