.privacy-policy {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

h1,
h2 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

p {
  margin-bottom: 1rem;
}

ul {
  list-style: disc;
  padding-left: 1;
}
