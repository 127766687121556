.ProfilePage {
  margin: 5vh auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
}

.ProfilePageImage > img {
  overflow: hidden;
  object-fit: cover;
  border-radius: 50%;
  height: 180px;
  width: 180px;
  border: 3px solid #8635ee;
}

.ProfilePageImage {
  margin-top: 40px;
  position: relative;
  border-radius: 100px;
}

.EditImageButton img {
  height: 20px;
  width: 20px;
  filter: invert(26%) sepia(83%) saturate(2138%) hue-rotate(253deg)
    brightness(90%) contrast(108%);
}

.EditImageButton {
  position: absolute;
  top: 160px;
  right: 40px;
  border-radius: 100%;
  border: none;
  background-color: #d0d0f2;
  padding: 5px;
  width: 30px;
  height: 30px;
}

.EditImagePopUp {
  border-radius: 10px;
  z-index: 10;
  height: auto;
  width: 40vw;
  padding: 5vh;
  font-size: 20px;
  position: fixed;
  top: auto;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.EditImagePopUp h3 {
  margin: 5px;
  text-align: left;
}

.ClosePopUpButton {
  position: absolute;
  right: 3vw;
  top: 45px;
  height: 12px;
  width: 12px;
  border: none;
}

.EditImagePopUp button {
  margin: 2vh 0 0 0;
  width: 150px;
  border-radius: 5px;
  font-size: 0.8em;
  border: none;
  transition: 0.5s;
  background-color: #8635ee;
  color: white;
}

.ReactCrop {
  max-height: 52vh;
}

.TextField input {
  margin: 5vh 0;
  width: 200px;
  border-radius: 5px;
  font-size: 0.75em;
  padding: 15px 15px;
  border: none;
  background-color: rgba(0, 0, 0, 0.12);
  color: black;
}

.EditImagePopUp input {
  margin: 3vh 0;
  text-align: left;
  width: 150px;
  border-radius: 5px;
  font-size: 0.75em;
  padding: 15px 15px 25px 15px;
  border: 3px dotted #8635ee;
  color: black;
}

input[type='file']::file-selector-button {
  color: black;
  padding: 1em 1em 1em 0;
  display: block;
  border-radius: 0.2em;
  margin: 0;
  border: none;
  background-color: #ffffff;
  transition: 0.5s;
}

.EditForm {
  padding: 35px 2.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 100px;
}

.InputField input,
.InputField textarea {
  padding: 10px;
  margin: 15px 0;
  border-radius: 5px;
  border: 1px solid black;
  color: black;
  width: 30vw;
  height: 30px;
  font-size: 16px;
}

.InputField textarea {
  resize: none;
  height: 120px;
}

.InputField {
  text-align: left;
}

.InputField label {
  font-size: 14px;
  display: block;
  font-weight: bold;
}

.SaveButton {
  border: none;
  background-color: #8635ee;
  margin: 10px;
  display: inline;
  color: white;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  height: 40px;
}

.SaveButton:hover {
  background-color: #111827;
  transition-duration: 0.2s;
}

@media (max-width: 520px) {
  .EditImagePopUp {
    width: 70vw;
  }

  .InputField input,
  .InputField textarea {
    width: 60vw;
  }
}

select {
  padding: 12px;
  height: 50px;
  width: 15vw;
  font-size: 16px;
  margin-right: 0;
  background-color: #111827;
  color: white;
  border-radius: 5px 0 0 5px;
  outline: none;
}

.AddSocialFieldForm {
  background-color: #111827;
  border-radius: 5px;
  padding-right: 5px;
}

.AddSocialLinkText {
  text-align: center;
  height: 40px;
  width: 50vw;
  padding: 4px;
  background-color: #111827;
  color: white;
  border: none;
  font-size: 16px;
  outline: none;
}

.SocialField {
  display: flex;
  flex-direction: row;
  margin: 10px;
  align-items: center;
}

#deleteIcon {
  height: 30px;
  width: 30px;
}

.deleteButton {
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  transition: 0.2s;
}

.deleteButton:hover {
  background-color: white;
}

.AddLinkButton {
  background-color: #111827;
  border: 1px solid transparent;
  border-radius: 0.75rem;
  box-sizing: border-box;
  color: white;
  font-size: 1.125rem;
  font-weight: 600;
  padding: 0.75rem 1.2rem;
  transition-duration: 0.2s;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: auto;
}

.AddLinkButton:hover {
  background-color: #374151;
}

/* Dropdown Button */
.dropbtn {
  background-color: #111827;
  color: white;
  padding: 16px;
  margin: 32px;
  border-radius: 5px;
  font-size: 16px;
  border: none;
  transition: 0.2s;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #111827;
  min-width: 160px;
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 200px; /* Set a maximum height for the dropdown content */
  overflow-y: auto;
}

/* Links inside the dropdown */
.dropdown-content p {
  color: white;
  padding: 12px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content p:hover {
  background-color: black;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: black;
}

.Link {
  background-color: #111827;
  background-image: none;
  border: 1px solid #111827;
  border-radius: 4px;
  box-shadow: #fff 4px 4px 0 0, #111827 4px 4px 0 1px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: ITCAvantGardeStd-Bk, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 10px 5px 10px 10px;
  overflow: visible;
  padding: 12px 40px;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.Link:focus {
  text-decoration: none;
}

.Link:hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .button-50 {
    padding: 12px 50px;
  }
}
