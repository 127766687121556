.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.sidebar {
  background: linear-gradient(to bottom right, #b180ff, #4b21a4);
  width: 20vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
}

.sidebar > h1 {
  font-size: 20px;
  color: white;
  font-weight: 800;
  margin: 30px;
}

.rightDiv > h2 {
  margin: 5vh 2vw 2vh 2vw;
  font-size: 24px;
  font-weight: 400;
}

.intro {
  margin: 0 2vw 3vh 2vw;
  display: flex;
}

.intro h1 {
  font-size: 46px;
  margin: 10px 0;
  font-weight: 400;
}

.intro p {
  font-size: 16px;
  color: #8635ee;
  margin: 0;
}

.intro img {
  height: 52px;
  width: 52px;
  margin: 15px 10px 15px 0;
  border-radius: 50%;
}

.navLinks {
  display: flex;
  flex-direction: column;
}

.navButton {
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  margin: 5px 10px;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 20px 10px;
}

.navButton img {
  height: 24px;
  width: 24px;
  padding: 0 10px;
  filter: invert(100%) sepia(13%) saturate(0%) hue-rotate(353deg)
    brightness(104%) contrast(101%);
}

.selectedNavButton {
  background-color: white;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.168);
  color: #8635ee;
  font-size: 14px;
  transition: 0.5s ease;
}

.selectedNavButton img {
  filter: invert(23%) sepia(36%) saturate(6941%) hue-rotate(259deg)
    brightness(95%) contrast(96%);
}

.logOutButton {
  width: 8vw;
  padding: 10px;
  margin: 20px 15px;
  display: flex;
  justify-content: center;
  color: #8635ee;
}

.rightDiv {
  margin-left: 20vw;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.myCard {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 0 5vw;
  height: 340px;
  margin: 0 1vw;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.168);
}

.cardOptions {
  display: flex;
  justify-content: space-between;
}

.cardOptions h3 {
  font-size: 28px;
  font-weight: 400;
}

.cardOptions a {
  font-size: 14px;
  font-weight: 400;
  color: #8635ee;
}

.cardLinks {
  display: flex;
  align-items: center;
}

.cardLinks a {
  margin: 5px;
  cursor: pointer;
}

.rightmainDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mainDiv {
  display: flex;
  margin: 1vh 0;
}

.shareDiv {
  height: 100px;
  border-radius: 5px;
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px;
  justify-content: center;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.168);
}

.shareDiv button {
  cursor: pointer;
}

.shareDivButtons button {
  width: 14.5vw;
  border-radius: 5px;
  padding: 5px;
  font-weight: 500;
  border: 1px solid #8635ee;
  color: #8635ee;
  background-color: white;
  font-size: 14px;
}

.shareButton {
  margin: 5px 0;
  font-size: 14px;
  border-radius: 5px;
  height: 32px;
  padding: 5px;
  border: 1px solid #8635ee;
  color: white;
  background-color: #8635ee;
}

.shareDivButtons {
  display: flex;
  height: 32px;
  justify-content: space-between;
}

.advertisement {
  border-radius: 5px;
  width: 31vw;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.168);
  text-align: center;
}

.advertisement img {
  width: 100%;
}

.contactsSections {
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.168);
  width: 75vw;
  margin: 1vw;
  padding: 10px;
  border-radius: 5px;
}

.contactsSections h1 {
  font-size: 24px;
  font-weight: 500;
}

.contactTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1vw;
}

.contactRow {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #939393;
}

.contactRow > div {
  display: flex;
}

.contactRow p {
  padding: 0 20px;
}

.contactRow > div > p {
  text-align: center;

  width: 13vw;
}

.contactHeading {
  color: #8635ee;
  font-size: 12px;
}

.contactHeading > div > p {
  width: 13vw;
}

.topNavBar {
  position: fixed;
  display: none;
}

.canvas {
  display: none;
}

@media (max-width: 1000px) {
  .advertisement {
    display: none;
  }

  .mainDiv {
    flex-direction: column;
    align-items: center;
  }

  .cardOptions {
    width: 80vw;
    max-width: 380px;
  }

  .intro {
    display: none;
  }

  .myCard {
    flex-direction: column-reverse;
    align-items: center;
    padding: 0 5vw;
    max-width: 400px;
    width: 80vw;
  }

  .sidebar {
    position: fixed;
    width: 64vw;
    z-index: 10;
    height: 100vh;
  }

  .logOutButton {
    width: 50vw;
    margin-bottom: 5lvh;
  }

  .hide {
    display: none;
  }

  .rightDiv {
    margin: 80px auto 0 auto;
    align-items: center;
  }

  .shareDiv {
    margin: 10px;
    width: 80vw;
    padding: 20px 5vw;
    max-width: 420px;
  }

  .shareDivButtons > button {
    max-width: 200px;
    width: 38vw;
  }

  .contactCompany {
    display: none;
  }

  .contactEmail {
    display: none;
  }

  .contactsSections {
    width: 90vw;
    padding: 10px 2vw;
  }

  .rightDiv > h2 {
    display: none;
  }

  .topNavBar {
    display: flex;
    width: 100vw;
    background: linear-gradient(to bottom right, #b180ff, #4b21a4);
    color: white;
    justify-content: space-between;
    height: 50px;
    align-items: center;

    z-index: 10;
  }

  .topNavBar h2 {
    font-weight: 900;
  }

  .topNavBar img {
    height: 24px;
    width: 24px;
    padding: 5px 10px;
    filter: invert(94%) sepia(5%) saturate(0%) hue-rotate(147deg)
      brightness(92%) contrast(97%);
  }

  .contactRow > div > p {
    width: auto;
  }
}
