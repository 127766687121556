.FTUEPage {
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.primary_color {
  color: #8635ee;
}

.left_pane {
  width: 65vw;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.nav_links {
  display: flex;
  align-items: center;
  padding: 7.5vh 7.5vw;
}

.nav_links img {
  height: 24px;
  width: 24px;
  padding: 5px;
}

.nav_links p {
  display: inline;
  margin: 5px;
}

.InputField {
  text-align: left;
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.InputField h3 {
  font-weight: 700;
  font-size: 16px;
  text-align: left;
}

.InputField input {
  height: 40px;
  border-radius: 5px;
  padding: 2px 10px;
  border: 1px solid #00000049;
  font-size: 16px;
}

.Page1 {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 5vh 10vw;
}

.Page1 h1 {
  font-size: 56px;
  margin: 0;
}

.Page1 h4 {
  font-size: 20px;
  font-weight: normal;
  margin: 10px 0 7.5vh 0;
}

.NextButton {
  width: 30vw;
  color: white;
  height: 32px;
  background-color: #8635ee;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  padding: 2px 10px;
  margin: 5vh 0;
}

/*****************/

.BusinessCard {
  background-color: white;
  color: black;
  border-radius: 20px;
  margin: 2.5vh 0;
}

.right_pane {
  width: 35vw;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #b180ff, #4b21a4);

  color: white;
  align-items: center;
}

.right_pane h2 {
  margin: 7.5vh 0;
  font-size: 34px;
}

.right_pane p {
  padding: 2.5vh 7.5vw;
  font-size: 16px;
  text-align: justify;
}

/**/
.socialsBoxContainer {
  display: flex;
}

.social img {
  height: 40px;
  width: 40px;
}

.social p {
  margin: 5px 0;
}

.social {
  margin: 15px;
}

.social span {
  color: #9b51e0;
  text-decoration: underline;
  font-size: 14px;
}

/*****/

.popup {
  border-radius: 10px;
  z-index: 200;
  height: 306px;
  width: 326px;
  padding: 5vh;
  font-size: 18px;
  position: fixed;
  top: 20%;
  left: 0;
  right: 0;
  margin-left: auto;
  background-color: white;
  margin-right: auto;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.168);
}

.AddSocialPopUp {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.AddSocialPopUp h4 {
  width: 100%;
  font-size: 20px;
  margin: 10px 0 20px 0;
}

.AddSocialPopUp img {
  height: 80px;
  width: 80px;
  margin: 5px;
}

.AddSocialPopUp h3 {
  font-size: 16px;
  font-weight: 500;
  margin: 5px;
}

.AddSocialPopUp input {
  margin: 10px;
  border: none;
  border-radius: 5px;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 12px;
  padding: 5px;
  width: 278px;
  text-align: center;
}

.AddSocialPopUp div {
  display: flex;
  justify-content: space-between;
}

.AddSocialPopUp button {
  height: 32px;
  width: 140px;
  margin: 4px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

.cancelButton {
  color: #f94d4d;
  border: 2px solid #f94d4d;
  background-color: white;
}

.saveButton {
  color: white;
  background-color: #8635ee;
  border: none;
}

.MoreSocialsPopUp {
  height: 400px;
  width: 546px;
}

.PopUpHeader {
  display: flex;
  text-align: left;
  justify-content: space-between;
  align-items: center;
}

.PopUpHeader h4 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.PopUpHeader p {
  margin: 0;
  font-size: 10px;
}

.PopUpHeader img {
  height: 24px;
  width: 24px;
}

.socialsCatalogue {
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.168);
  display: grid;
  grid-template-columns: repeat(2, auto);
  border-radius: 10px;
  background-color: white;
  margin: 20px 0;
  overflow-y: scroll;
  height: 340px;
}

.socialGridItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 16px;
}

.socialGridItem > div {
  display: flex;
  align-items: center;
}

.socialGridItem img {
  height: 18px;
  width: 18px;
  padding: 10px;
}

.socialGridItem h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.addSocialButton {
  border: 1px solid #6200e8;
  border-radius: 10px;
  font-size: 24px;
  height: 32px;
  width: 32px;
  background-color: white;
}

.Loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loaderHeader h1 {
  font-size: 56px;
  margin: 0;
}

.loaderHeader h4 {
  color: #8635ee;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}

.Loading p {
  position: fixed;
  bottom: 0;
  color: #8635ee;
}

@keyframes ldio {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio div {
  position: absolute;
  width: 48px;
  height: 48px;
  border: 6px solid #8635ee;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio div {
  animation: ldio 1s linear infinite;
  top: 50px;
  left: 100px;
}
.loadingioSpinner {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background-color: #ffffff;
}
.ldio {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */

/****************/

/**/

.EditImagePopUp input {
  display: none;
}

.EditImagePopUp {
  height: auto;
  top: 5vh;
  width: 40vw;
}

.uploadImageIcon {
  height: 50px;
  width: 50px;
}

.SaveImgButton {
  width: 120px;
  height: 40px;
}

.uploadBox {
  margin-top: 25px;
  background-color: rgba(155, 81, 224, 0.08);
  border-color: #9b51e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
  border-style: dashed;
  border-width: 2px;
  padding: 2rem 0.5rem;
  gap: 0.4rem;
}

.uploadDesc {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
  margin: 0;
}

.uploadButton {
  font-size: 12px;
  height: 40px;
}

.uploadButton div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.uploadButton p {
  margin: 0;
}

.uploadButton img {
  height: 15px;
  width: 15px;
  margin-right: 5px;
  filter: invert(100%) sepia(13%) saturate(0%) hue-rotate(353deg)
    brightness(104%) contrast(101%);
}

.cropCanvas {
  height: 150px;
  width: 150px;
  object-fit: contain;
  display: none;
}

.cropImageFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}

.cropImageFooter p {
  text-align: left;
  margin-right: 5px;
}

.cropImageFooter button {
  margin: 0;
}

.alertText {
  color: #b3261e;
}

/**/

@media (max-width: 1150px) {
  .right_pane {
    width: 50vw;
  }
}

@media (max-width: 950px) {
  .EditImagePopUp {
    height: auto;
    top: 5vh;
    width: 80vw;
  }

  .right_pane {
    display: none;
  }

  .left_pane {
    width: 100vw;
    align-items: center;
  }

  .Page1 {
    padding: 0;
  }

  .Page1 h1 {
    font-size: 42px;
  }

  .Page1 h4 {
    margin: 10px 0 20px 0;
  }

  .InputField {
    width: 80vw;
    max-width: 400px;
  }

  .InputField h3 {
    margin: 18px 0 8px 0;
  }

  .NextButton {
    width: 80vw;
    max-width: 400px;
  }

  .socialsBoxContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .nav_links {
    padding: 5vh 2vw;
  }

  .socialsCatalogue {
    grid-template-columns: repeat(1, auto);
    margin: 20px 0;
    height: 340px;
  }

  .MoreSocialsPopUp {
    width: 80vw;
    max-width: 546px;
    height: 420px;
  }
}
