.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px;
  background-color: #8535ee13;
  border: 5px solid #8635ee;
  border-radius: 20px;
  height: 140px;
  width: 340px;
}

.QRCode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.168);
}

.QRCode img {
  background-color: white;
  border-radius: 15px;
  padding: 12px;
  height: 80px;
  width: 80px;
}

.TextInfo {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.TextInfo h1 {
  font-size: 24px;
  margin: 2.5px 0;
  font-weight: 500;
  text-align: left;
}

.TextInfo h3 {
  font-size: 14px;
  font-weight: 400;
  margin: 2.5px 0;
}

@media (max-width: 440px) {
  .root {
    width: 73vw;
    height: 32vw;
  }

  .root h1 {
    font-size: 20px;
  }

  .QRCode {
    height: 80px;
    width: 80px;
  }
}

@media (max-width: 370px) {
  .QRCode {
    padding: 5px;
    height: 70px;
    width: 80px;
  }
}

@media (max-width: 330px) {
  .QRCode {
    padding: 0 5px;
    height: 70px;
    width: 80px;
  }
}
