.backButton {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header > div {
  display: flex;
  align-items: center;
}

.header h2 {
  color: black;
  padding: 0 15px;
}

.root {
  margin: 5vh 20vw;
  padding: 50px;
  display: flex;
  flex-direction: row;
  color: black;
  background-color: white;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
  z-index: 1;
}

.updateChangesButton {
  background-color: #8635ee;
  border: none;
  border-radius: 5px;
  color: white;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.168);
  padding: 10px;
  cursor: pointer;
}

.socialsDiv {
  padding: 0 2.5vw;
  text-align: left;
}

.socialsBoxContainer {
  display: flex;
  text-align: center;
}

.social img {
  height: 40px;
  width: 40px;
}

.social p {
  margin: 5px 0;
}

.social {
  margin: 15px;
}

.social span {
  color: #9b51e0;
  text-decoration: underline;
  font-size: 14px;
}

.submitDiv {
  text-align: left;
  padding: 2.5vw;
}

.socialsBoxContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

@media (max-width: 1000px) {
  .root {
    margin: 5vh 0;
    flex-direction: column;
    align-items: center;
  }

  .socialsBoxContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .submitDiv {
    text-align: center;
  }

  .header > .updateChangesButton {
    display: none;
  }
}
